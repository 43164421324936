import React from 'react';

import Button from 'ui-kit/button/button';

import { ButtonVariantProps } from './button.props';
import './empty-state.style.scss';

export interface EmptyStateProps {
    icon: React.ComponentType;
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    buttonLabel?: string | React.ReactNode;
    onNavigate?: () => void;
    dataGALocation?: string;
    buttonVariant?: ButtonVariantProps;
}

const EmptyState: React.FC<EmptyStateProps> = ({
    icon: Icon,
    title,
    description,
    buttonLabel,
    onNavigate,
    dataGALocation,
    buttonVariant = 'outline-primary'
}) => {
    return (
        <div className="empty-state">
            <Icon />

            <div className="empty-state__content">
                <h4>{title}</h4>
                <p>{description}</p>
            </div>

            {buttonLabel && onNavigate && (
                <div className="empty-state__button">
                    <Button
                        async
                        className="sm-full"
                        label={buttonLabel}
                        variant={buttonVariant}
                        type="button"
                        {...(dataGALocation ? { dataGALocation } : {})}
                        onClick={onNavigate}
                    />
                </div>
            )}
        </div>
    );
};

export default EmptyState;
