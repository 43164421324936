import React from 'react';
import { Col, Row } from 'react-bootstrap';

// UI Kits
import BirdiAccordion from 'ui-kit/accordion/accordion';
import { AccordionCustomCtaProps } from 'ui-kit/accordion/accordion.props';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';

import { PaymentInfoCtaProps, PaymentProps } from './payment-history-info.props';

const PaymentInfoCta = ({ payment, translation }: PaymentInfoCtaProps) => {
    return (
        <div className="payment-info-cta">
            <Row>
                <Col sm={12} md={6} xl={7} className="d-flex align-items-center">
                    <span className="birdi-accordion-toggle-title-wrapper">
                        {translation('pages.profile.paymentHistory.payment.title', {
                            amount: payment.GLPostAmount
                        })}
                    </span>
                </Col>
                <Col sm={12} md={6} xl={5} className="d-flex align-items-center">
                    <div className="payment-number-container text-left text-md-right w-100 font-weight-bold">
                        <span>{payment.displayDate}</span>
                        <span className="payment-number-label ml-3">
                            {translation('pages.profile.paymentHistory.payment.status')}:
                        </span>
                        <span>
                            {translation(`pages.profile.paymentHistory.statusTypes.${payment.GLPostStatusNum}`)}
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

const PaymentHistoryInfo = ({ payment, translation }: PaymentProps) => {
    const renderPaymentInfoBody = () => {
        return (
            <div className="payment-details-toggle-container pb-0">
                <div className="table-responsive">
                    <table className="table table-borderless table-sm">
                        <tbody>
                            <tr
                                className={
                                    !payment.orderPaymentCardNumber || !payment.orderPaymentCardTypeDesc ? 'd-none' : ''
                                }
                            >
                                <td className="px-0">{translation('pages.profile.paymentHistory.payment.cardInfo')}</td>
                                <td className="payment-details-card-number">
                                    {translation('pages.profile.paymentHistory.payment.cardTypeAndNumber', {
                                        type: payment.orderPaymentCardTypeDesc,
                                        number: payment.orderPaymentCardNumber.substring(
                                            payment.orderPaymentCardNumber.length - 4
                                        )
                                    })}
                                </td>
                                <td className="text-right px-0">
                                    <CreditCardIcon
                                        className="payment-details-credit-card-icon"
                                        variant={payment.orderPaymentCardTypeDesc}
                                    />
                                </td>
                            </tr>
                            <tr className={payment.orderPaymentCardNumber || !payment.paymentCardInfo ? 'd-none' : ''}>
                                <td className={'px-0'} colSpan={3}>
                                    {payment.paymentCardInfo}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <BirdiAccordion.Toggle
            variant={'full-cta'}
            includeTitleIcon={false}
            startToggled={true}
            customCta={({ isToggled }: AccordionCustomCtaProps) => (
                <PaymentInfoCta isToggled={isToggled} payment={payment} translation={translation} />
            )}
        >
            {renderPaymentInfoBody()}
        </BirdiAccordion.Toggle>
    );
};

export default PaymentHistoryInfo;
