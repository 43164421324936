import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { noop } from 'util/function';
import CtaStack from 'ui-kit/cta-stack/cta-stack';
import './pagination.scss';

//
// --- Types ---

interface PaginationProps {
    currentPage: number;
    isNextPageDisabled?: boolean;
    onNextPageClick?: () => void;
    onPreviousPageClick?: () => void;
}

//
// --- Pagination Component ---

const Pagination: React.FC<PaginationProps> = (props) => {
    const { currentPage = 1, isNextPageDisabled = false, onPreviousPageClick = noop, onNextPageClick = noop } = props;

    const { t } = useTranslation();

    return (
        <Container className="pagination" fluid>
            <Row>
                <Col xs={12} lg={{ span: 10, offset: 1 }} className="w-100 d-flex justify-content-between">
                    <CtaStack
                        label={t('components.pagination.prevPage')}
                        iconDirection="left"
                        onClick={onPreviousPageClick}
                        disabled={currentPage === 1}
                    />
                    <span>
                        {t('components.pagination.currentPage', {
                            currentPage
                        })}
                    </span>
                    <CtaStack
                        label={t('components.pagination.nextPage')}
                        onClick={onNextPageClick}
                        disabled={isNextPageDisabled}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default Pagination;
